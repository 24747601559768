const eventsReducerDefaultState = {list: []};

export default (state = eventsReducerDefaultState, action) => {
    switch (action.type) {
      case 'SET_EVENTS':
        return {
          list: action.events, 
          total_count: action.count
        };
      case 'REMOVE_EVENTS':
        return {
          list: []
        };
      default:
        return state;
    }
  };
