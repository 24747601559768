const analyticsReducerDefaultState = {};

export default (state = analyticsReducerDefaultState, action) => {
    switch (action.type) {
      case 'SET_ANALYTICS':
        return action.analytics;
      case 'REMOVE_ANALYTICS':
        return {};
      default:
        return state;
    }
  };
