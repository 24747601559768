const parkingReducerDefaultState = {
  list: []
};

export default (state = parkingReducerDefaultState, action) => {
  switch (action.type) {
    case 'SET_PARKING':
      return {
        list: action.parking,
        total_count: action.count
      };
    case 'REMOVE_PARKING':
      return { list: [] };
    default:
      return state;
  }
};
