const voiEventsReducerDefaultState = [];

export default (state = voiEventsReducerDefaultState, action) => {
    switch (action.type) {
      case 'SET_VOI_EVENTS':
        return action.voiEvents;
      case 'REMOVE_VOI_EVENTS':
        return [];
      default:
        return state;
    }
  };
