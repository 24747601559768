import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/ie11"; // For IE 11 support
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import axios from "axios";
import config from "./config";
import AppRouter from "./routers/AppRouter";
import store from "./store/configureStore";
import { setLogin } from "./actions/auth";
import { setBranchList } from "./actions/branches";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

const jsx = (
    <Provider store={store}>
        <AppRouter />
    </Provider>
);


export const getBranchList = (secretKey, location) => {
    return new Promise((resolve, reject) => {
        let access = JSON.parse(localStorage.getItem("access"));
        let branches = undefined;
        if (config["ORG_CODE"]) {
            const org_code = config["ORG_CODE"];

            const url = `https://a-eye.ai:8000/branch_lists?code=${org_code}&secretKey=${secretKey}&selectedLocation=${location}`
            axios
                .get(url)
                .then((response) => {
                    branches = response.data.branches;
                    let user = {};
                    const org = branches.find(d => d?.LOCATION === access?.organisation?.LOCATION)
                    user["organisation"] = org;
                    user["user_id"] = access?.user_id;
                    user["user_name"] = access?.user_name;
                    user["token"] = access?.token;
                    store.dispatch(setLogin(user));
                    store.dispatch(setBranchList(branches));
                    // checkUser(branches);
                    ReactDOM.render(jsx, document.getElementById("root"));

                    resolve(true);
                })
                .catch((err) => {
                    console.log(err);
                    // history.push("/");

                    resolve(true);
                });
        } else {
            branches = [config];
            store.dispatch(setBranchList(branches));
            checkUser(branches);
            resolve(true);
        }
    })
};


const access = localStorage.getItem("access");

const checkUser = (branches) => {
    if (branches && access) {
        access = JSON.parse(access);
        const headers = {
            Authorization: `Bearer ${access.token}`,
        };
        axios
            .get(`${access.url}check_user`, { headers })
            .then(({ data }) => {
                if (data.user) {
                    branches.some((element) => {
                        if (element.URL === access.url) {
                            data.user["organisation"] = element;
                            data.user["cam"] = "ALL";
                            data.user["violation"] = "ALL";
                            data.user["uid"] = data.user.uuid;
                            data.user["access_token"] = access.token;
                            store.dispatch(setLogin(data.user));
                            return true;
                        }
                    });
                    ReactDOM.render(jsx, document.getElementById("root"));
                } else {
                    throw new Error("Error");
                }
            })
            .catch((err) => {
                console.log(err);
                // history.push("/");
                ReactDOM.render(jsx, document.getElementById("root"));
            });
    } else ReactDOM.render(jsx, document.getElementById("root"));
};

const renderApp = () => {
    !access?.user_id ? getBranchList() : ReactDOM.render(jsx, document.getElementById("root"));
};

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
