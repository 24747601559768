import {TOGGLE_UPLOAD_IMAGE_MODAL, TOGGLE_DOWNLOAD_IMAGE_MODAL} from "../action-types/common";

const defaultState = {
    uploadImageModal: {isOpen: false},
    downloadImageModal: {isOpen: false}
}

const CommonReducer = (state = defaultState, action) => {
    switch (action?.type) {
        case TOGGLE_UPLOAD_IMAGE_MODAL:
            return {...state, uploadImageModal: action?.payload};
        case TOGGLE_DOWNLOAD_IMAGE_MODAL:
            return {...state, downloadImageModal: action?.payload};
        default:
            return state;
    }
}
export default CommonReducer;