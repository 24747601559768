const defaultState = {
    auth: {}
}
export default (state = defaultState, action) => {

    const author = {...state.auth, ...action.user}
    switch (action.type) {
        case "LOGIN":
            return { 
                ...state, 
                auth: author
            }.auth

        case "LOGOUT":
            return {
                uid: undefined,
            };
        default:
            return state;
    }
};