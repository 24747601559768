import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import analyticsReducer from '../reducers/analytics';
import authReducer from '../reducers/auth';
import branchReducer from '../reducers/branches';
import eventsReducer from '../reducers/events';
import voiEventsReducer from '../reducers/voi_events';
import echallanReducer from '../reducers/echallan';
import parkingReducer from '../reducers/parking';
import CommonReducer from '../reducers/common';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
        combineReducers({
            analytics: analyticsReducer,
            auth: authReducer,
            branches: branchReducer,
            events: eventsReducer,
            voi_events: voiEventsReducer,
            vio_details: echallanReducer,
            parking: parkingReducer,
            common: CommonReducer
        }),
        composeEnhancers(applyMiddleware(thunk))
    );
