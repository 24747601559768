import React from "react";
import {Redirect, Route} from "react-router-dom";

export const PrivateRoute = ({
                                 isAuthenticated,
                                 component: Component,
                                 ...rest
                             }) => {
    let access = JSON.parse(localStorage.getItem("access"));
    return <Route
        {...rest}
        component={(props) =>
            access?.token ? <Component {...props} /> : <Redirect to="/"/>
        }
    />
}
export default (PrivateRoute);
