export default {
  URL: "https://visionzero.live:5000/api/v1/",
  LOC_NAME: "central",
  LOCATION: "Warangal",
  ORG_CODE: "TSDTES",
  VIOLATIONS: {
    SPEED: 6,
    WRONG_ROUTE: 1,
    NO_HELMET: 2,
    TRIPLE_RIDING: 3,
    HEAVY: 4,
    LANE_DISCIPLINE: 5,
    MANUAL: 10
  },

  DETECTED_VIOLATION_TO_ECHALLAN_VIOLATIONS: {
    SPEED: "Over speeding / Dangerous Driving",
    WRONG_ROUTE: "Wrong Side Driving",
    NO_HELMET: "Without Helmet/Not wearing helmet by rider",
    TRIPLE_RIDING: "Triple Riding",
    HEAVY: "Carrying Goods dangerously",
    LANE_DISCIPLINE: "Lane/line Crossing",
    MANUAL: ""
  },

  VIOLATIONS_TO_ECHALLAN_VIOLATIONS: [
    { value: "01", label: "Without Helmet/Not wearing helmet by rider"},
    { value: "02", label: "Triple Riding"},
    { value: "96", label: "Not wearing helmet by pillion rider" },
    { value: "03", label: "Cell Phone Driving" },
    { value: "27", label: "Wrong Side Driving"},
    { value: "39", label: "Violation of stop line" },
    { value: "30", label: "Minor driving the vehicle" },
    { value: "18", label: "Lane/line Crossing"},
    { value: "26", label: "Over speeding / Dangerous Driving"},
    { value: "86" , label: "Lane Jumping"},
    { value: "97", label: "Wearing improper/Half/Non-standard helmet"},
    { value: "138", label: "Not tying the helmet properly/Not putting the strap"},
    { value: "04", label: "Signal Jumping" },
    { value: "05", label: "W/o Seat Belt" },
    { value: "06", label: "Dangerous Driving" },
    { value: "07", label: "Extra Passengers" },
    { value: "08", label: "Piloting with Driver" },
    { value: "09", label: "Extra School Children" },
    { value: "10", label: "Parking or Wait near Bus stop" },
    { value: "11", label: "Improper BUS stopping" },
    { value: "12", label: "Bus stopping at middle of the road" },
    { value: "13", label: "Improper / Irregular No. Plate" },
    { value: "14", label: "W/o Uniform" },
    { value: "16", label: "Roof Travelling" },
    { value: "17", label: "Dazzling Light" },
    { value: "19", label: "Stop line / Zebra line Crossing" },
    { value: "25", label: "Emitting more noise pollution" },
    { value: "29", label: "No Right/Left Turn/U Turn" },
    { value: "31", label: "Zig-Zag and reckless driving" },
    { value: "32", label: "Failure to give right of way" },
    { value: "36", label: "Stopping on the carriage way" },
    { value: "40", label: "Violation of one way rule" },
    { value: "42", label: "Restriction on plying of vehicles" },
    { value: "43", label: "Using prohibited routes" },
    { value: "45", label: "Auto/Cab Extra Demand" },
    { value: "49", label: "Carrying passengers in HTV/LGV" },
    { value: "50", label: "Carrying Goods in Private Vehicles" },
    { value: "51", label: "Carrying Goods dangerously" },
    { value: "52", label: "Wrong Parking in the carriage way" },
    { value: "54", label: "Wrong/Unauthorized Parking" },
    { value: "55", label: "Parking on service roads" },
    { value: "60", label: "No parking at Commercial Centers" },
    { value: "62", label: "No parking in front of entry/exit gates" },
    { value: "68", label: "Violation of LL conditions(Without L board)" },
    { value: "70", label: "Extra Projection on top" },
    { value: "74", label: "No Entry" },
    { value: "78", label: "Tinted glass/Black Film" },
    { value: "81", label: "Extra Projection" },
    { value: "82", label: "Cause an obstruction to pedestrian walkway" },
    { value: "85", label: "Extra Projection either side" },
    { value: "89",label: "Disobeying Traffic Signal / No entry/No right/No U Turn"},
    { value: "92", label: "Without Mirror" },
    { value: "104", label: "Racing and Trail of speed" },
    { value: "112", label: "No entry for Lorry" },
    { value: "113", label: "No entry for DCM" },
    { value: "114", label: "BUS/Lorry/HMV Unauthorized Parking" },
    { value: "118", label: "Refusal to Ply" },
    { value: "121", label: "No Entry and Disobedience of orders" },
    { value: "122", label: "Obstruction of free left" },
    { value: "124", label: "Wilfully withholding the vehicle information" },
  ],
};
