const echallanReducerDefaultState = {};

export default (state = echallanReducerDefaultState, action) => {
    switch (action.type) {
      case 'SET_VIO_DETAILS':
        return action.vio_details;
      case 'REMOVE_VIO_DETAILS':
        return {};
      default:
        return state;
    }
  };
