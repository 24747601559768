import React from "react";
import { Router, Route, Switch, Link, NavLink } from "react-router-dom";
import Loadable from "react-loadable";
import { createBrowserHistory } from "history";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import "./../AppRouter.scss";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = Loadable({
  loader: () => import("./../components/DefaultLayout"),
  loading,
});

// Pages
const Login = Loadable({
  loader: () => import("./../views/Pages/Login"),
  loading,
});

const Register = Loadable({
  loader: () => import("./../views/Pages/Register"),
  loading,
});

const Page404 = Loadable({
  loader: () => import("./../views/Pages/Page404"),
  loading,
});

const Page500 = Loadable({
  loader: () => import("./../views/Pages/Page500"),
  loading,
});

const Users = Loadable({
  loader: () => import("../components/LiveView"),
  loading,
});

export const history = createBrowserHistory();

const AppRouter = () => (
  <Router history={history}>
    <div>
      <Switch>
        <PublicRoute path="/" exact component={Login} />
        <PrivateRoute exact path="/dashboard" component={DefaultLayout} />
        <PrivateRoute exact path="/liveview" component={DefaultLayout} />
        <PrivateRoute exact path="/vehicle_events" component={DefaultLayout} />
        <PrivateRoute exact path="/wanted_vehicles" component={DefaultLayout} />
        <PrivateRoute exact path="/echallan" component={DefaultLayout} />
        <PrivateRoute exact path="/parking" component={DefaultLayout} />
        <PrivateRoute exact path="/camera-tiles" component={DefaultLayout} />
        <PrivateRoute exact path="/camera-tiles/:id" component={DefaultLayout}/>
        <PrivateRoute exact path="/camera-map" component={DefaultLayout} />
        <PrivateRoute exact path="/camera-map/:id" component={DefaultLayout}/>
        <PrivateRoute
          exact
          path="/camerahealthmonitoring"
          component={DefaultLayout}
        />
        <PrivateRoute
          exact
          path="/inferencestreams"
          component={DefaultLayout}
        />
        <PrivateRoute exact path="/devices" component={DefaultLayout} />
        <PrivateRoute
          exact
          path="/hotlistedvehicles"
          component={DefaultLayout}
        />
        <PrivateRoute exact path="/cameras" component={DefaultLayout} />
        <PrivateRoute exact path="/reports" component={DefaultLayout} />
        <PrivateRoute
          exact
          path="/licensemanagement"
          component={DefaultLayout}
        />
        <Route exact component={Page404} />
      </Switch>
    </div>
  </Router>
);

export default AppRouter;
